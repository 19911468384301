import React from 'react';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';

import './App.css';

function App() {
  return (
    <div>
      <Header className="header"/>
      <div className="main-content"> 
        <section id="home" style={{marginTop:"62px"}}>
          <Home />
        </section>

        <section id="services" className="mt-2">
          <Services />
        </section>

        <section id="about" className="margin-5">
          <AboutUs />
        </section>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
