import React from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/AboutUs.css';

const AboutUs = () => {
  return (
    <div className="container mt-5">
      <div className="text-center mb-5">
        <h3 className="fw-bold">About Us</h3>
         <p>We are established player with <strong>12+ years</strong> of experience in the passenger mobility segment providing a secured and reliable travel experience to commuters in their daily travel through an easy tech based booking platform. </p>
      </div>

      <div className="row">
        <div className="col-md-4 mb-4">
          <Card className="h-100 shadow-lg card-custom">
            <Card.Body>
              <Card.Title className="mb-3">Comfortable & Assured Seats</Card.Title>
              <Card.Text>
                <ul>
                  <li>Comfortable & Assured Seats</li>
                  <li>Well Trained Drivers</li>
                  <li>Clean & Hygienic Buses</li>
                  <li>Limited Stops (Faster Commute)</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4 mb-4">
          <Card className="h-100 shadow-lg card-custom">
            <Card.Body>
              <Card.Title className="mb-3">Backoffice Support & Live Bus Tracking</Card.Title>
              <Card.Text>
                <ul>
                  <li>Backoffice Support</li>
                  <li>Live Bus Tracking</li>
                  <li>Guaranteed Seating</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4 mb-4">
          <Card className="h-100 shadow-lg card-custom">
            <Card.Body>
              <Card.Title className="mb-3">Travel Subscriptions & Limited Stops</Card.Title>
              <Card.Text>
                <ul>
                  <li>Affordable Prices</li>
                  <li>Limited Stops</li>
                  <li>CCTV Camera</li>
                 </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
