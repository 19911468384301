import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Services.css';
import Link from 'react'

const Services = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center bg-">
        <div className="col-md-10 col-12">
          <div className="card" style={{backgroundColor:'rgba(0, 0, 0, 0.05)'}}>
            <div className="card-body">
              <div className="row">
              <div className="col-md-6">
  <a style={{ textDecoration: 'none' }} href="https://vmsacademy.com/main.asp?page=Bus_Book&branchid=2130" target="_blank" rel="noopener noreferrer">
    <Button 
      variant="primary" 
      className="custom-button animated pulse infinite" id="custom-button"
    >
      Book A Trip
    </Button>
  </a>
</div>

<div className="col-md-6">
  <a style={{ textDecoration: 'none' }} href="https://docs.google.com/forms/d/e/1FAIpQLSdF7invPLT6PhwA6DSm18eJvb4MhHmgVnVECgFp4zY9aoQXag/viewform" target='_blank' rel="noopener noreferrer">
    <Button 
      variant="primary" 
      className="custom-button animated rubberBand infinite" 
      id="custom-button"
    >
      Enquiry
    </Button>
  </a>
</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-5">
          <h3><span className="urbanride-heading">Welcome to <span className="urbanride-name">URBANRIDE</span> Bus Services</span></h3>
          <p>Your trusted companion for safe, comfortable, and seamless journeys across the city.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
