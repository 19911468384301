import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Footer.css';

const Footer = () => {
  return (
    <footer className="custom-footer pt-4 pb-4">
      <div className="container text-center">
        <div className="row">
          <div className="col-12">
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li>Address: 104, Vijay Tower, Lazarus Road, Paanch Pakhadi, Thane West, 400602</li>
              <li>Email: info@urbanride.co.in</li>
              <li>Phone: 9820207792 (WhatsApp available)</li>
            </ul>
          </div>
        </div>
        <div className="mt-12">
          <p>
            &copy; copyrights reserved with URBANRIDE. Designed & Developed by{' '}
            <a href="http://www.shiveninfotech.com" target="_blank" rel="noopener noreferrer">
              www.shiveninfotech.com
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
