import React from 'react';
import carouselImage1 from '../assets/images/carouselImage1.jpg'; 
import carouselImage2 from '../assets/images/carouselImage2.jpg'; 
import carouselImage3 from '../assets/images/carouselImage3.jpg'; 

import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Home.css';

const Home = () => {
  return (
    <div>
      <div className="row">
        <Carousel interval={2000} >
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={carouselImage1}
              alt="First slide"
            />
            <Carousel.Caption>
            {/* <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)", borderRadius:'10px', padding:'5px'}}>
              <h3 style={{color:"black", fontWeight:"bold",textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)"}}>Comfortable Rides</h3>
              <p style={{color:"black", fontWeight:"bold"}}>Experience the comfort of our modern buses.</p>
              </div> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={carouselImage2}
              alt="Second slide"
            />
            <Carousel.Caption>
            {/* <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)", borderRadius:'10px', padding:'5px'}}>
              <h3 style={{color:"black", fontWeight:"bold", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)"}}>Reliable Service</h3>
              <p style={{color:"black", fontWeight:"bold"}}>Always on time, ensuring you reach your destination safely.</p>
              </div> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={carouselImage3}
              alt="Third slide"
            />
            <Carousel.Caption>
              {/* <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)", borderRadius:'10px', padding:'5px'}}>
              <h3 style={{color:"black", fontWeight:"bold", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)"}}>Affordable Prices</h3>
              <p style={{color:"black", fontWeight:"bold"}}>Get the best travel experience at an affordable price.</p>
              </div> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Home;
