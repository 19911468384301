import React from 'react';
import UrbanRideLogo from '../assets/images/UrbanRideLogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Header.css'; 

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="#home"> 
            <img src={UrbanRideLogo} alt="Bus Service Logo" className="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
         
        </div>
      </nav>
    </header>
  );
};

export default Header;
